import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import BaseService from '@/services/base-service';
import ProcessFlowModel from '@/models/crm/process-flow.model';
import ProcessKanbanSettingsModel from '@/models/crm/process-kanban-settings.model';
import ProcessModel from '@/models/crm/process.model';
import { PaginationModel } from '@/models/pagination.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { RequestConfig } from '@/interfaces/request-config.interface';

@injectable()
export default class ProcessService extends BaseService {
  async getProcesses(
    clientId: string,
    type: ClientTypeEnum,
    limit = 0,
    returnActivesOnly = true,
  ): Promise<ProcessModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        limit,
        type,
        returnActivesOnly,
      },
    };

    return (await this.httpService.get(
      ProcessModel,
      `${this.getApiPath()}/crm/processo/list`,
      config,
    )) as ProcessModel[];
  }

  async getAttendantProcesses(offset: number): Promise<PaginationModel<ProcessModel>> {
    const config: RequestConfig = {
      params: {
        offset,
      },
    };
    return (await this.httpService.get(
      PaginationModel,
      `${this.getApiPath()}/attendant/processes`,
      config,
    )) as PaginationModel<ProcessModel>;
  }

  async getFlows(): Promise<ProcessFlowModel[]> {
    return (await this.httpService.post(
      ProcessFlowModel,
      `${this.getApiPath()}/processos/fluxosUsuario?token=${this.getApiToken()}`,
      {},
    )) as ProcessFlowModel[];
  }

  async getKanbanSettings(): Promise<ProcessKanbanSettingsModel> {
    return (await this.httpService.get(
      ProcessKanbanSettingsModel,
      `${this.getApiPath()}/crm/processo/kanban-settings`,
      {},
    )) as ProcessKanbanSettingsModel;
  }

  getKanbanUrl(
    urlKanbanApp: string,
    urlKanbanApi: string,
    flowId: number,
    clientId: string,
    clientType: ClientTypeEnum,
    defaultQueue: number,
    processId?: number,
  ): string {
    let kanbanUrl = `${urlKanbanApp}?token=${this.getApiToken()}`;
    kanbanUrl += `&origin=crm360&urlIgs=${urlKanbanApi}&fluxo=${flowId}`;

    if (clientType === ClientTypeEnum.Prospect) {
      kanbanUrl += `&defaultProspect=${clientId}`;
    } else {
      kanbanUrl += `&defaultClient=${clientId}`;
    }

    if (processId) {
      kanbanUrl += `&onLoadOpenProcess=${processId}`;
    } else {
      kanbanUrl += '&onLoadCreateProcess=true';
    }

    if (defaultQueue && defaultQueue > 0) {
      kanbanUrl += `&defaultQueue=${defaultQueue}`;
    }

    return kanbanUrl;
  }

  getNewKanbanUrl(
    flowId: number,
    clientId: string,
    clientType: ClientTypeEnum,
    defaultQueue: number,
    processId?: number,
    isProcessView?: boolean,
  ): string {
    let kanbanUrl = `${this.getApi360Path()}`;
    if (isProcessView) {
      kanbanUrl = `${kanbanUrl}/crm/processo`
      + `/${processId ? `${processId}` : '0'}`
      + `${flowId ? `!idFluxo=${flowId}` : ''}`;
    } else {
      kanbanUrl = `${kanbanUrl}/crm/workflow/kanban/${flowId}`
      + `${processId ? `!${processId}` : ''}`;
    }
    if (!processId) {
      kanbanUrl = `${kanbanUrl}`
      + `${clientType ? `!tipoCliente=${ClientTypeEnum.Client.toString() === clientType
        ? 'CLIENTE' : clientType.toUpperCase()}` : ''}`
      + `${clientId ? `!idCliente=${clientId}` : ''}`
      + `${defaultQueue && defaultQueue !== -1 ? `!filaPadrao=${defaultQueue}` : ''}`;
    }
    kanbanUrl = `${kanbanUrl}!token=${this.getApiToken()}`;
    kanbanUrl = kanbanUrl.replace(/!/, '?');
    kanbanUrl = kanbanUrl.replaceAll('!', '&');
    return kanbanUrl;
  }

  static generateProcessExportFilename(date: Date): string {
    return `Processos_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
